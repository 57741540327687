import React from 'react';
import {Box, Grid, Button} from "@mui/material";

const projects = [
    {
        title: "GitHub - Hot Plugged DNS",
        description: "View the project on GitHub.",
        imageSrc: "/projects/hp-dns.png",  // Screenshot of the GitHub page
        link: "https://github.com/manoonabbasi/hot-plugged-DNS"
    },
    {
        title: "Directory Sync System with Media Monitoring",
        description: "Freelance Project",
        imageSrc: "/projects/upwork.png",  // Screenshot of the GitHub page
        link: "https://www.upwork.com/freelancers/~010d31e68ac964c6a7?p=1792098046768570368"
    },
    {
        title: "Ecommerce Startup",
        description: "Tours startup development",
        imageSrc: "/projects/ddt.png",  // Screenshot of the GitHub page
        link: "https://dubaidesert.tours"
    },
    {
        title: "Email Validator",
        description: "View the project on GitHub.",
        imageSrc: "/projects/makra.png",  // Screenshot of the GitHub page
        link: "https://github.com/manoonabbasi/makra"
    },
    {
        title: "NIST CSF - Cybersecurity",
        description: "View the project on GitHub.",
        imageSrc: "/projects/nist.png",  // Screenshot of the GitHub page
        link: "https://github.com/manoonabbasi/CyberSecurity_Analyst/tree/master/Internal%20Audits/manoonsLLC_NIST_CSF"
    },
];

export default function Portfolio() {
    return (
        <Box id={'portfolio'}>
            <Grid container spacing={4} justifyContent={'center'}>
                {projects.map((project, index) => (
                    <Grid item xs={12} md={6} key={index}>
                        <ProjectBlock project={project} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

function ProjectBlock({project}) {
    const {title, description, imageSrc, link} = project;

    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} p={2} boxShadow={3}>
            <h3>{title}</h3>
            <p>{description}</p>
            <Box 
                sx={{ 
                    width: '70%', 
                    height: '300px', 
                    maxWidth: '400px',
                    overflow: 'hidden', 
                    border: '1px solid #ccc',
                    mb: 2
                }}
            >
                {imageSrc ? (
                    <img
                        src={imageSrc}
                        alt={title}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                        }}
                    />
                ) : (
                    <p>Image not available</p>
                )}
            </Box>
            <Button variant="contained" color="primary" href={link} target="_blank">
                Visit
            </Button>
        </Box>
    );
}
